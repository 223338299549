export default [{
  label: 'Razão social', field: 'razaoSocial', type: 'custom',
},
{ label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'tamanho-cpf col-nowrap' },
{
  label: 'Mês Base',
  field: 'mesBase',
  thClass: 'col-nowrap pr-3',
  thType: 'custom-header',
  type: 'custom',
},
{ label: 'DARF', field: 'temDarf', type: 'custom' },
{ label: 'DAS', field: 'temDas', type: 'custom' },
{ label: 'DAE', field: 'temDae', type: 'custom' },
{ label: 'DJE', field: 'temDje', type: 'custom' },
{
  label: 'Consultado em',
  field: 'consultadoEm',
  thClass: 'col-nowrap',
  type: 'custom',
},
{ label: 'Próxima consulta', field: 'proximaConsulta', thClass: 'col-nowrap' }]
