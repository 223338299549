import axios from '@axios'

const PREFIX = 'comprovante-pagamento'
export default {

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  baixaEmLote(filtros, nomeArquivo) {
    return axios.post(`${PREFIX}/baixa-lotes?nomeArquivo=${nomeArquivo}`, filtros)
  },

  getComprovantes(empresaId, tipoComprovante, filtros) {
    return axios.post(`${PREFIX}/${empresaId}?tipoComprovante=${tipoComprovante}`, filtros)
  },

  getURL(id) {
    return axios.get(`${PREFIX}/${id}/url`)
  },

  notificarComprovante(nomeEmpresa, emailEmpresa, emailCc, mensagemAdicional, definirEmailPadrao, id) {
    return axios.post(`${PREFIX}/notifica?id=${id}&emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`)
  },

  baixarSelecionados(tipo, comprovantesIds) {
    return axios.post(`${PREFIX}/baixar-selecionadas?tipoComprovante=${tipo}`, comprovantesIds)
  },
}
