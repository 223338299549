<template>
  <b-card>

    <b-row align-h="end">
      <b-col cols="auto">
        <download-em-lote
          tipo-arquivo="todos os Comprovantes"
          tipo="Comprovante Arrecadação"
          :filtro="filtroAtual.filtroBy"
          :palavras-filtro="[['DARF', 'DAS', 'DAE', 'DJE']]"
          @baixar-lote="baixarEmLote"
        >
          <template slot="avisos-baixa-lote">
            <div
              v-b-tooltip.hover
              title="Os comprovantes serão exibidos de 2022 em diante"
              style="padding-right: 5px;"
              class="text-primary"
            >
              <feather-icon
                icon="InfoIcon"
                size="32"
                stroke-width="0.8"
              />
            </div>
          </template>
        </download-em-lote>
      </b-col>
    </b-row>

    <comprovantes-list
      ref="comprovantesList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      tem-filtro-extra
      esconder-acoes
    >

      <template
        slot="filtro-extra"
      >
        <b-col>
          <b-form-input
            v-model="filtroCustomData"
            type="month"
            @change="mudarFiltroData($event)"
          />
        </b-col>
      </template>

      <template
        slot="custom-header"
        slot-scope="{ props} "
      >
        <span
          v-if="props.column.field === 'mesBase'"
          v-b-tooltip.hover
          title="Refere-se a data da arrecadação consultada no portal do e-CAC"
        >
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <span
          v-if="props.column.field === 'razaoSocial'"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>

        <b-badge
          v-if="props.column.field === 'mesBase' && props.row[props.column.field] == 'Todos os meses'"
          variant="light-primary"
          class="badge-size-p"
        >
          {{ props.row[props.column.field] }}
        </b-badge>
        <span
          v-if="props.column.field === 'mesBase' && props.row[props.column.field] != 'Todos os meses'"
        >
          {{ props.row[props.column.field] }}
        </span>

        <b-btn
          v-if="props.column.field === 'temDarf'"
          v-b-tooltip.hover
          :title="!props.row.habilitarBotaoDarf ? 'Buscando dados' : ''"
          variant="none"
          style="padding: 0.2rem;"
          :disabled="!props.row.temDarf"
          :class="props.row.temDarf ? 'text-success' : ''"
          @click="openModalComprovantes(props.row.habilitarBotaoDarf, props.row, { tipo: 'DARF', tipoId: '1,2' })"
        >
          <font-awesome-icon
            icon="fa-money-check-dollar"
            size="xl"
          />
        </b-btn>
        <b-btn
          v-if="props.column.field === 'temDas'"
          v-b-tooltip.hover
          :title="!props.row.habilitarBotaoDas ? 'Buscando dados' : ''"
          variant="none"
          style="padding: 0.2rem;"
          :class="props.row.temDas ? 'text-success' : ''"
          :disabled="!props.row.temDas"
          @click="openModalComprovantes(props.row.habilitarBotaoDas, props.row, { tipo: 'DAS', tipoId: 4 })"
        >
          <font-awesome-icon
            icon="fa-money-check-dollar"
            size="xl"
          />
        </b-btn>
        <b-btn
          v-if="props.column.field === 'temDae'"
          v-b-tooltip.hover
          :title="!props.row.habilitarBotaoDae ? 'Buscando dados' : ''"
          variant="none"
          style="padding: 0.2rem;"
          :class="props.row.temDae ? 'text-success' : ''"
          :disabled="!props.row.temDae"
          @click="openModalComprovantes(props.row.habilitarBotaoDae, props.row, { tipo: 'DAE', tipoId: 5 })"
        >
          <font-awesome-icon
            icon="fa-money-check-dollar"
            size="xl"
          />
        </b-btn>
        <b-btn
          v-if="props.column.field === 'temDje'"
          v-b-tooltip.hover
          :title="!props.row.habilitarBotaoDje ? 'Buscando dados' : ''"
          variant="none"
          style="padding: 0.2rem;"
          :class="props.row.temDje ? 'text-success' : ''"
          :disabled="!props.row.temDje"
          @click="openModalComprovantes(props.row.habilitarBotaoDje, props.row, { tipo: 'DJE', tipoId: 3 })"
        >
          <font-awesome-icon
            icon="fa-money-check-dollar"
            size="xl"
          />
        </b-btn>

        <b-badge
          v-if="props.column.field === 'consultadoEm' && props.row[props.column.field] == 'Buscando dados'"
          variant="light-primary"
          class="badge-size-p"
        >
          {{ props.row[props.column.field] }}
        </b-badge>
        <span
          v-if="props.column.field === 'consultadoEm' && props.row[props.column.field] != 'Buscando dados'"
        >
          {{ props.row[props.column.field] }}
        </span>
      </template>
    </comprovantes-list>

    <envio-email
      v-if="comprovanteEmailId > 0"
      :ref="`modalEnvioEmail${comprovanteEmailId}`"
      :title="`Enviar Comprovante de Arrecadação ${comprovantesSelecionados.tipo}`"
      :sugestao="empresaSelecionada.emailEmpresa"
      :sugestao-email-adicional="empresaSelecionada.emailAdicionalEmpresa"
      @enviarEmail="enviarComprovante($event)"
      @hide="fecharModalEnviarComprovantes"
    />

    <modal-comprovantes
      v-if="mostrarComprovantes"
      :info-comprovantes="comprovantesSelecionados"
      :empresa="empresaSelecionada"
      :mes-base="filtroCustomData"
      @hidden="fecharComprovantes"
    />

    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      titulo-carregamento="Aguarde!"
      :descricao="`Estamos realizando o download do${addPlural} comprovante${addPlural}.`"
    />

    <visualizar-arquivo
      v-if="mostrarArquivo && arquivoUrl"
      :url="arquivoUrl"
      tem-cabecalho-custom
      @hidden="fecharArquivo()"
    >
      <template v-slot:cabecalho-custom>
        <div class="cabecalho mb-0 pr-1 pl-1 bg-white rounded-top">
          <b-row class="py-1">
            <b-col class="d-flex align-items-center">
              <h5 class="m-0">
                Comprovante de arrecadação e-CAC - {{ comprovantesSelecionados.tipo }}</h5>
            </b-col>
            <b-col
              cols="auto"
            >
              <b-btn
                v-b-tooltip.hover
                title="Enviar por e-mail"
                variant="none"
                class="btn-none icone-acao m-0 pb-0 pt-0"
                @click="openModalEnviarComprovante(comprovantesSelecionados.comprovantes[0].id || 0 )"
              >
                <feather-icon
                  icon="SendIcon"
                  class="text-primary"
                  size="20"
                />
              </b-btn>
              <b-btn
                class="btn-outline-secondary mx-50 p-50 rounded"
                @click="fecharArquivo()"
              >
                <font-awesome-icon icon="fa-solid fa-xmark" />
              </b-btn>
            </b-col>
          </b-row>
        </div>
      </template>
    </visualizar-arquivo>
  </b-card>
</template>

<script>
import moment from 'moment'
import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'ComprovanteVisualizacao',
  components: {
    ComprovantesList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    ModalComprovantes: () => import('./shared/components/modal/ModalComprovantes.vue'),
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  data() {
    return {
      botoesOption: null,
      visualizacao: {
        acaoClass: 'col-td-acao-4',
        disabledCheckbox: true,
        placeholderBusca: 'Buscar por Razão Social, CNPJ/CPF, DARF, DAS, DAE, DJE',
      },
      filtros: [],
      filtrosBase: [{ colunas: ['CadastroFederal', 'CadastroFederalSemMascara', 'RazaoSocial'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Darf',
          colunas: ['TipoDocumento'],
          label: 'DARF',
          valor: '1,2',
        },
        {
          id: 'Das',
          colunas: ['TipoDocumento'],
          label: 'DAS',
          valor: 4,
        },
        {
          id: 'Dae',
          colunas: ['TipoDocumento'],
          label: 'DAE',
          valor: 5,
        },
        {
          id: 'Dje',
          colunas: ['TipoDocumento'],
          label: 'DJE',
          valor: 3,
        },
        {
          id: 'ComComprovante',
          colunas: ['ComComprovante'],
          label: 'Com comprovante',
          valor: true,
        },
        {
          id: 'SemComprovante',
          colunas: ['ComComprovante'],
          label: 'Sem comprovante',
          valor: false,
        }],
      filtroAtual: {},
      filtroCustomData: '',
      filtroTipoDownload: null,
      mostrarArquivo: false,
      arquivoUrl: '',
      mostrarComprovantes: false,
      empresaSelecionada: {
        emailEmpresa: [],
        emailAdicionalEmpresa: [],
      },
      comprovanteEmailId: 0,
      comprovanteEmailIds: [],
      comprovantesSelecionados: {
        tipo: '',
        tipoId: '',
        comprovantes: null,
      },
      isLoading: false,
      addPlural: false,
    }
  },
  created() {
    const mes = moment().subtract(1, 'month').format('yyyy-MM')

    this.filtroCustomData = this.$route.query.filtroMes ? this.$route.query.filtroMes : mes

    if (!this.filtroCustomData) {
      this.filtroCustomData = mes
    }

    this.filtros = [...this.filtrosBase, { valor: this.filtroCustomData, colunas: ['DataArrecadacao'] }]
    this.visualizacao.colunas = colunas
    this.visualizacao.evento = this.carregar
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.comprovantesList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.comprovantesList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
    mudarFiltroData(data) {
      const dataFinal = data
      const coluna = ['DataArrecadacao']
      const filtroData = { valor: dataFinal, colunas: coluna }

      const index = this.$refs.comprovantesList.enviarServidor.filtros.findIndex(where => where.colunas.length === 1 && where.colunas.some(any => filtroData === null || any === 'DataArrecadacao'))

      if (index >= 0) {
        this.$refs.comprovantesList.enviarServidor.filtros.splice(index, 1)
      }

      const filtronew = [...this.filtrosBase, filtroData]
      this.filtros = filtronew
      this.$refs.comprovantesList.filtroImutavel = [...filtronew]
      this.$refs.comprovantesList.aplicarFiltroRapido(filtroData, false)
    },
    baixarEmLote(evt) {
      api.baixaEmLote(this.filtroAtual, evt)
    },
    buscarComprovantes(filtroAtivo) {
      const filtros = filtroAtivo || { filtroBy: [], ordemBy: [] }

      if (filtros.filtroBy.length === 0) {
        filtros.filtroBy = [...filtros.filtroBy, { valor: this.filtroCustomData, colunas: ['DataArrecadacao'] }]
      }

      return api.getComprovantes(this.empresaSelecionada.id, this.comprovantesSelecionados.tipoId, filtros)
    },
    openModalComprovantes(botaoHabilitado, row, comprovantesSelecionados) {
      if (botaoHabilitado) {
        this.empresaSelecionada = {
          id: row.id,
          razaoSocial: row.razaoSocial,
          emailEmpresa: row.emailEmpresa,
          emailAdicionalEmpresa: row.emailAdicionalEmpresa,
        }
        this.comprovantesSelecionados = comprovantesSelecionados

        this.buscarComprovantes()
          .then(payload => {
            this.comprovantesSelecionados.comprovantes = payload.data

            if (payload.data.length > 1 && !this.mostrarArquivo) {
              this.mostrarComprovantes = true
            } else {
              this.openVisualizacaoArquivo(payload.data[0].id, payload.data[0].numeroDocumento)
            }
          })
          .catch(err => {
            this.$erro('Não foi possível abrir a modal dos comprovantes.', err)
            this.fecharComprovantes()
          })
      }
    },
    openVisualizacaoArquivo(id) {
      api.getURL(id)
        .then(payload => {
          this.arquivoUrl = payload.data
          this.mostrarArquivo = true
        })
        .catch(err => {
          this.$erro('Não foi possível abrir a visualização dos comprovantes.', err)
          this.fecharComprovantes()
        })
    },
    fecharComprovantes() {
      this.mostrarComprovantes = false
      this.fecharArquivo()
    },
    fecharArquivo() {
      this.mostrarArquivo = false

      if (!this.mostrarComprovantes) {
        this.comprovantesSelecionados = {
          tipo: '',
          tipoId: '',
          comprovantes: null,
        }
        this.empresaSelecionada = {
          emailEmpresa: [],
          emailAdicionalEmpresa: [],
        }
      }
    },
    openModalEnviarComprovante(id) {
      if (Array.isArray(id)) {
        const ids = id.join('')
        this.comprovanteEmailIds = id
        this.comprovanteEmailId = ids
      } else {
        this.comprovanteEmailId = id
      }
      setTimeout(() => this.$refs[`modalEnvioEmail${this.comprovanteEmailId}`].$refs.modalEnviarEmail.show(), 100)
    },
    fecharModalEnviarComprovantes() {
      this.comprovanteEmailId = 0
      this.comprovanteEmailIds = []
    },
    enviarComprovante(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc.length === 0) {
        this.$erro('Informe ao menos um e-mail')
      } else {
        api.notificarComprovante(this.empresaSelecionada.razaoSocial, itens.emailsPara, itens.emailsCc, itens.mensagensAdicional, itens.definirEmailPadroes, this.comprovanteEmailIds.length > 0 ? this.comprovanteEmailIds : this.comprovanteEmailId)
          .then(() => {
            this.$sucesso('Comprovante enviado com sucesso')
          })
          .catch(err => {
            this.$erro(err.message || 'Não foi possível enviar as notificações.')
            throw err
          })
      }
    },
    baixarComprovantesSelecionados(tipo, linhas) {
      this.isLoading = true
      this.addPlural = linhas.length > 1 ? 's' : ''
      api.baixarSelecionados(tipo, linhas)
        .then(payload => {
          window.open(payload.data)
          this.$sucesso(`Download do${this.addPlural} comprovante${this.addPlural} finalizado.`)
        })
        .catch(err => {
          this.$erro(err.message || 'Ocorreu um erro no download.')
          throw err
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}

</script>
